import React, { useState, useEffect } from "react";
import Destacados from "./destacados";
import Marcas from "./marcas";
import SwipProd from "./swip-prod";
import Usos from "../services/usos";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";

const servusos = new Usos();

const Home = () => {
  const navigate = useNavigate();
  const [formulario, setFormulario] = useState({
    nombre: "",
    mail: "",
    telefono: "",
    mensaje: "",
  });
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/",
      },
    });
  }, []);
  const handlerChange = (e) => {
    e.preventDefault();
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    });
  };

  const handlerSubmit = (e) => {
    e.preventDefault();
    servusos.sendMail_confirmacioncheckin(formulario).then((resp) => {
      if (resp.data.success) {
        Swal.fire({
          icon: "success",
          title: "Mensaje enviado !",
          showConfirmButton: false,
        });
        setFormulario({
          nombre: "",
          mail: "",
          telefono: "",
          mensaje: "",
        });
        setTimeout(() => {
          Swal.close();
          navigate("/gracias");
        }, 1000);
      }
    });
  };

  return (
    <>
      <div className="contenedorHome">
        <div className="portada" id="home">
          <img src="/media/Portada.png" alt="portada" />
        </div>
        <div className="productos" id="producto">
          <h1 className="titulo">LÍNEA DE PRODUCTOS</h1>
          <div className="contProd">
            <SwipProd />
          </div>
          <hr />
        </div>
        <div className="contDestacados" id="destacados">
          <h1 className="titulo">PRODUCTOS DESTACADOS</h1>
          <Destacados />
          <button className="botonCatalogo">
            <a
              href="/file/Catalogo-Londero.pdf"
              download={"Catalogo-Lodero.pdf"}
            >
              Descargar catálogo completo
            </a>
          </button>
          <hr />
        </div>
        <div className="zonas" id="zonas">
          <h1 className="titulo">ZONAS</h1>
          <div className="contenidoZonas">
            <p>
              Desde sus orígenes, nuestra firma concentró sus esfuerzos en
              atender las necesidades de los emprendimientos comerciales de
              Villa Nueva y Villa María. Nuestra evolución fue acompañada por un
              progresivo crecimiento del ámbito de influencia, llegando
              abastecer con nuestros productos a clientes radicados en grandes y
              pequeñas localidades de la provincia, tales como Canals, Bell
              Ville, Ballesteros, General Deheza, Río Cuarto, Río Tercero,
              Playosa.
            </p>
            <p>
              A pesar de haber satisfecho las necesidades de insumos comerciales
              de clientes de más de 160 localidades, en la actualidad, arriba
              del 50% de nuestros clientes se concentran en nuestra región, más
              precisamente en las localidades de Villa María y Villa Nueva. La
              segunda zona en nivel de importancia, es el corredor Villa
              María-Villa General Belgrano, para el cual contamos con un canal
              logístico propio disponible para la entrega de productos y la
              provisión de servicios en las localidades de Arroyo Cabral, Luca,
              Hernando, General Fotheringam, Tancacha, Río Tercero, Almafuerte,
              Embalse, Villa del Dique, Villa Rumipal, Santa Rosa de
              Ctalamuchita y Villa General Belgrano, con miras de ampliar el
              alcance hasta la localidad de Los Reartes.
            </p>
          </div>
          <hr />
        </div>
        <div className="marca">
          <h1 className="titulo">NUESTRAS PRINCIPALES MARCAS</h1>
          <div className="contMarcas">
            <Marcas />
          </div>
          <hr />
        </div>
        <div className="contacto" id="contacto">
          <h1 className="titulo">CONTACTO</h1>
          <form className="formulario">
            <div className="inputs">
              <div>
                <label>Nombre</label>
                <input
                  type="text"
                  name="nombre"
                  id="nombre"
                  value={formulario.nombre}
                  onChange={handlerChange}
                  placeholder="Ingresa tu nombre"
                />
              </div>
              <div>
                <label>E-mail</label>
                <input
                  type="text"
                  name="mail"
                  id="mail"
                  value={formulario.mail}
                  onChange={handlerChange}
                  placeholder="Ingresa tu mail"
                />
              </div>
              <div>
                <label>Telefono</label>
                <input
                  type="text"
                  name="telefono"
                  value={formulario.telefono}
                  onChange={handlerChange}
                  placeholder="Ingresa tu telefono"
                />
              </div>
            </div>
            <div className="textarea">
              <label>Mensaje</label>
              <textarea
                onChange={handlerChange}
                type="text"
                name="mensaje"
                id="mensaje"
                value={formulario.mensaje}
                placeholder="Dejanos tu mensaje"
              />
            </div>
          </form>
          <button className="butonEnviar" onClick={handlerSubmit}>
            Enviar
          </button>
        </div>
      </div>
      <style jsx="true">{`
        hr {
          width: 90%;
          margin-top: 6rem;
        }
        .contenedorHome {
          padding-top: 5.4rem;
        }
        .portada img {
          width: 100%;
        }
        .productos {
          text-align: center;
          padding: 2rem 0;
        }

        .titulo {
          font-size: 50px;
          color: var(--secondary);
        }

        .contProd {
          display: flex;
          justify-content: center;
        }

        .contDestacados {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .botonCatalogo {
          margin-top: 3rem;
          cursor: pointer;
          border: none;
          color: white;
          background: #332a70;
          border-radius: 2px;
          font-size: 22px;
          padding: 0.5rem 1.5rem;
          width: fit-content;
        }

        .botonCatalogo a {
          text-decoration: none;
          color: white;
        }

        .zonas {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .contenidoZonas {
          width: 90%;
          font-size: 18px;
          font-weight: 400;
        }

        .contenidoZonas p {
          margin: 0;
          line-height: 30px;
        }
        .marca {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .contacto {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 6rem;
        }
        .contMarca {
          width: 90%;
        }

        .formulario {
          display: flex;
          width: 90%;
          gap: 2rem;
        }
        .inputs {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 2rem;
        }
        .inputs input {
          height: 40px;
          width: 100%;
          border: solid 1px black;
          border-radius: 3px;
          padding-left: 0.5rem;
        }

        .inputs label {
          font-weight: 500;
        }
        .textarea {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .textarea textarea {
          width: 100%;
          height: 100%;
          border: solid 1px black;
          padding-top: 1rem;
          border-radius: 3px;
          padding-left: 0.5rem;
        }
        .textarea label {
          font-weight: 500;
        }

        .butonEnviar {
          margin-top: 2rem;
          width: fit-content;
          padding: 0.7rem 1.5rem;
          border-radius: 3px;
        }

        @media only screen and (max-width: 1024px) {
          hr {
            width: 90%;
            margin-top: 3rem;
          }
          .titulo {
            text-align: center;
            font-size: 25px;
          }
          .botonCatalogo {
            margin-top: 1rem;
          }
          .contenidoZonas p {
            font-size: 12px;
            line-height: 18px;
          }
          .zonas {
            width: 100%;
          }
          .formulario {
            flex-direction: column;
            gap: 1rem;
          }
          .inputs {
            width: 100%;
            gap: 1rem;
          }
          .inputs input {
            height: 35px;
          }
          .inputs label {
            font-size: 14px;
          }
          .textarea {
            width: 100%;
          }
          .textarea label {
            font-size: 14px;
          }
          .textarea textarea {
            padding-left: 0rem;
            height: 200px;
          }
          .butonEnviar {
            width: 90%;
          }
        }
      `}</style>
    </>
  );
};

export default Home;
