import React from "react";

const arrMarca = [
  { foto: "/media/marca1.png" },
  { foto: "/media/marca2.png" },
  { foto: "/media/marca3.png" },
  { foto: "/media/marca4.png" },
  { foto: "/media/marca5.png" },
  { foto: "/media/marca6.png" },
  { foto: "/media/marca7.png" },
  { foto: "/media/marca8.png" },
  { foto: "/media/marca9.png" },
  { foto: "/media/marca10.png" },
];

const Marcas = () => {
  return (
    <>
      <div className="marcas">
        {arrMarca.map((p, i) => (
          <div key={i} className="cardMarca">
            <img src={p.foto} alt="foto" />
          </div>
        ))}
      </div>
      <style jsx="true">{`
        .marcas {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 4rem;
        }
        .cardMarca {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20%;
        }
        @media only screen and (max-width: 1024px) {
          .cardMarca {
            width: auto;
          }
        }
      `}</style>
    </>
  );
};

export default Marcas;
