import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const arrProd = [
  { foto: "/media/producto1.png", nombre: "HOGAR" },
  { foto: "/media/producto2.png", nombre: "CARNICERÍA" },
  { foto: "/media/producto3.png", nombre: "GASTRONOMÍA" },
  { foto: "/media/producto4.png", nombre: "ALMACEN" },
  { foto: "/media/producto5.png", nombre: "HOTELERIA" },
  { foto: "/media/producto6.png", nombre: "PANADERÍA" },
];
console.log(window.innerWidth);
const SwipProd = () => {
  const [bndMobile, setBndMobile] = useState(4);

  useEffect(() => {
    const setResize = () => {
      if (window.innerWidth >= 1050) {
        return setBndMobile(4);
      } else if (window.innerWidth >= 850) {
        return setBndMobile(3);
      } else if (window.innerWidth >= 650) {
        return setBndMobile(2);
      }
      return setBndMobile(1);
    };
    setResize();
    // evento que sucede cuando la ventana cambia de tamaño
    window.addEventListener(
      "resize",
      function (event) {
        setResize();
      },
      true
    );
  }, []);
  return (
    <>
      <div className="slide">
        <Swiper
          modules={[Navigation]}
          navigation
          spaceBetween={50}
          slidesPerView={bndMobile}
        >
          {arrProd.map((p, i) => (
            <SwiperSlide key={i}>
              <div className="card">
                <h1>{p.nombre}</h1>
                <img src={p.foto} alt="foto" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <style jsx="true">{`
        .slide {
          width: 90%;
        }
        .card {
          position: relative;
          border-radius: 3px;
          min-height: 265px;
          min-width: 270px;
        }

        .card h1 {
          background: var(--primary);
          width: 100%;
          font-size: 30px;
          color: white;
          position: absolute;
          margin: 0;
          padding: 0.3rem 0;
          font-weight: 100;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
        }

        .card img {
          width: 100%;
          border-radius: 3px;
        }
      `}</style>
    </>
  );
};

export default SwipProd;
