import { useEffect } from "react";
import Footer from "./components/footer";
import Header from "./components/header";
import Home from "./components/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Gracias from "./pages/gracias";
import WppButton from './components/wpp-button'
import TagManager from "react-gtm-module";


// //genero el id del contenedor
const TagManagerArgs = {
  gtmId:"GTM-N38NFSN"
}
// //inicializo el contenedor, inserto el script del tag manager en la app
TagManager.initialize(TagManagerArgs)
// // cuando se carga la app, dispara un evento
// //datalayet: hace un push a un array que se llama datalayer
// TagManager.dataLayer({
//   dataLayer:{
//     event:'pageview',
//     path:"/home/",
//   }
// })

function App() {
  useEffect(() => {
    document.title = "Londero - Equipamiento comercial";
  }, []);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <Home />
                <Footer />
                <WppButton />
              </>
            }
          ></Route>
          <Route
            path="/gracias"
            element={
              <>
                <Gracias />
              </>
            }
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
