import React from "react";

const Producto = ({ prod, handleClose, i }) => {
  const mensaje = (producto) => {
    const pedido = `_¡Hola! Te hago una consulta por el producto ${producto}`;
    return pedido;
  };
  const enviarPedido = async (producto) => {
    const pedido = mensaje(producto);
    window.open(`https://wa.me/5493536578023/?text=${pedido}`, "_blank");
  };

  return (
    <>
      <div className="contenedorProducto">
        <div className="imagen">
          <button
            onClick={(ev) => {
              handleClose(ev, i);
            }}
          >
            <span className="material-icons-outlined">highlight_off</span>
          </button>
          <img src={prod.foto} alt="foto" />
        </div>
        <div className="prodInfo">
          <div>
            <h1>{prod.titulo}</h1>
            <p>{prod.info}</p>
          </div>
          <button onClick={() => enviarPedido(prod.titulo)}>
            Consultar por el producto
          </button>
        </div>
      </div>
    </>
  );
};

export default Producto;
