const WppButton = () => {
  return (
    <>
      <div className="wpp-btn">
        <a
          href="https://wa.me/5493536578023"
          title="Comunicate con nosotros por whatsapp!"
          className="btn"
          id="wppbtn"
          target="_blank" rel="noreferrer"
        >
          <i className={`bx bxl-whatsapp visi`}></i>
        </a>
      </div>
      <style jsx="true">{`
        .wpp-btn {
          position: fixed;
          bottom: 3%;
          right: 1%;
          z-index: 10;
        }
        .wpp-btn .btn {
          display: flex;
          text-decoration: none;
          cursor: pointer;
          border: none;
          background: #00bb2d;
          color: white;
          padding: 0.7rem 0.8rem;
          border-radius: 100%;
          transition: 0.3s all;
          filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.1));
          box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
            rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5;
        }
        .wpp-btn .btn:hover {
          background-color: #028f23;
        }
        .btn i {
          font-size: 30px;
          display: flex;
          align-items: center;
        }

        .invi {
          opacity: 0;
          position: absolute;
        }
        .visi {
          opacity: 100;
          position: relative;
        }
      `}</style>
    </>
  );
};

export default WppButton;
