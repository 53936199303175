import React, { useEffect, useState } from "react";
import Producto from "./producto";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const dataDestacados = [
  {
    foto: "/media/destacado1.png",
    titulo: "Exhibidora Confitera CUBO 1.20 3N – Marca Grupo Cheff",
    info: "Ideales para exhibicion y venta de postres, tortas, masas finas, sandwiches, pastas frescas y comidas | disponibles en diferentes medidas de frente y alto (niveles) | Medidas: 1.17x1.20x0.70 mts (altoxfrentexprof) | Potencia: 1/3 HP | Vidrio termopanel | Puertas de vidrio corredizas | Vision 360° | Conserva la frescura y humedad de tus productos, sin humedad en los paneles.",
    open: false,
  },
  {
    foto: "/media/destacado2.png",
    titulo: "Horno Convector 10 bandejas – Marca: Technocalor",
    info: "Capacidad de 10 bandejas de 45×70 cm (o 20 bandejas gastronómicas 1/1) | | Ideal para panaderías, pastelerías, hoteles, restaurantes y catering. | Producción estimada: 60 kg de pan por hora. | Velocidad de calefacción de 0° a 200°C de 9 a 10 minutos. | Tablero totalmente digitalizado, control automático de temperatura, timer, vaporizador | Luz interior y luz testigo de piloto encendido | Equipado con válvula de seguridad autorizada por Enargas.",
    open: false,
  },
  {
    foto: "/media/destacado3.png",
    titulo: "Exhibidora Walk in Cooler 3 ptas ",
    info: "Permite exhibir y almacenar productos al mismo tiempo | Puertas frontales para autoservicio + puerta lateral de ingreso a depósito | Totalmente modulares | Opcional: de 3 a 10 puertas.",
    open: false,
  },
  {
    foto: "/media/destacado4.png",
    titulo: "Batea Carnicera Lamponia 2.40 mts Vidrio Curvo",
    info: "Vidrio Curvo, lateral de vidrio | Iluminación led | Gabinete exterior en chapa electrolizada-prepintada en color rojo | Piso interior en acero inoxidable brillante | Medidas: 1.35x2.40x1.00mts(altoxfrentexprof) | Disponible en 1.60, 2.00 y 3.00 mts de frente..",
    open: false,
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
};

const Destacados = () => {
  const [arrDestacados, setArrDestacados] = useState([]);

  useEffect(() => {
    setArrDestacados(dataDestacados);
  }, []);
  const handleOpen = (ev, i) => {
    ev?.preventDefault();
    console.log(i);
    const newarr = arrDestacados;
    newarr[i].open = true;
    setArrDestacados([...newarr]);
    
  };
  const handleClose = (ev, i) => {
    ev?.preventDefault();
    const newarr = arrDestacados;
    newarr[i].open = false;
    setArrDestacados([...newarr]);
  };
  return (
    <>
      <div className="destacados">
        {arrDestacados.map((p, i) => (
          <div className="contenedorCard" key={i}>
            <div
              className="cardProducto"
              aria-hidden
              onClick={(ev) => handleOpen(ev, i)}
            >
              <img src={p.foto} alt="foto" />
              <div className="ver">
                <button>Ver producto</button>
              </div>
            </div>
            <Modal
              open={p.open}
              onClose={(ev) => handleClose(ev, i)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-description"
                  className="contenedorProducto"
                >
                  <Producto prod={p} i={i} handleClose={handleClose} />
                </Typography>
              </Box>
            </Modal>
          </div>
        ))}
      </div>
      <style jsx="true">
        {`
        button {
          font-size: 18px;
          border: none;
          padding: 0.8rem;
          cursor: pointer;
          width: 100%;
          background-color: #332a70;
          color: white;
        }
          .destacados {
            display: flex;
            justify-content: space-between;
            gap: 3rem;
            width: 90%;
          }

          .contenedorCard {
            position: relative;
            box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.432);
            border-radius: 3px;
            overflow: hidden;
            transition: 1s all ease;
            width: 300px;
            height: 300px;
          }

          .cardProducto {
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            transition: 0.5s all ease;
            transform: translateY(15%) !important;
          }
          .ver {
            width: 100%;
          }

          .cardProducto:hover {
            transform: translateY(-2%) !important;
          }

          @media only screen and (max-width: 1024px) {
            .destacados {
              justify-content: space-between;
              padding: 0 1rem 2rem 3rem;
              overflow-x: scroll;
              margin-bottom: 1rem;
              width: 80%;
              padding-bottom: 4rem;
            }
            .destacados::-webkit-scrollbar-track {
              border: 1px solid #00000021;
              padding: 1rem 1rem;
              .0.0
              background-color: white;
            }

            .destacados::-webkit-scrollbar {
              width: 7px;
              height: 7px;
            }

            .destacados::-webkit-scrollbar-thumb {
              border-radius: 10px;
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              border: 1px solid #00000024;
            }

            .contenedorCard {
              width: 300px;
              overflow: visible;
              height: auto;
            }
            .cardProducto:hover {
              transform: translateY(15%) !important;
            }
          }
        `}
      </style>
    </>
  );
};

export default Destacados;
