import React from "react";
const Footer = () => {
  return (
    <>
      <div className="contenedorFooter">
        <ul className="info">
          <li className="direccion">
            <i className="bx bxs-map"></i>
            <p>Av. Carranza 50 -</p>
            <p>Villa Nueva, Córdoba, Argentina</p>
          </li>
          <li>
            <i className="bx bxs-phone"></i>+54 9 3536 57-8023
          </li>
          <li>
            <i className="bx bxl-whatsapp"></i>353 4269328
          </li>
          <li>
            <i className="bx bxs-envelope"></i>info@londero.com.ar
          </li>
        </ul>
        <div>
          <img src="/media/logoFoot.png" alt="foto" />
        </div>
      </div>
      <style jsx="true">{`
        .contenedorFooter {
          background: var(--primary);
          display: flex;
          justify-content: space-between;
          padding: 1rem 4rem;
          align-items: center;
        }

        .info {
          list-style: none;
          color: white;
        }

        .info li {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.5rem;
          font-size: 18px;
          font-weight: 100;
        }

        .info li span,
        .info li i {
          font-size: 25px;
        }
        .direccion p {
          margin: 0;
        }
        @media only screen and (max-width: 1024px) {
          .contenedorFooter {
            flex-direction: column-reverse;
            padding: 2rem 1rem;
            text-align: center;
          }

          .contenedorFooter img {
            width: 80%;
          }
          .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 0;
          }
          .direccion {
            display: flex;
            flex-direction: column;
          }
          .direccion p {
            margin: 0;
          }
          .info li {
            font-size: 14px;
          }
        }
      `}</style>
    </>
  );
};

export default Footer;
