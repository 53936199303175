import React, { useState } from "react";

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="contenedor">
        <div>
          <a href="#home">
            <img src="/media/logo.png" alt="logo" />
          </a>
        </div>
        <nav className="navDesktop">
          <ul className="navigate">
            <li>
              <a href="#producto" target="_self">
                Linea de producto
              </a>
            </li>
            <li>
              <a href="#destacados"> Productos</a>
            </li>
            <li>
              <a href="#zonas">Zonas</a>
            </li>
            <li>
              <a href="#contacto">Contacto</a>
            </li>
          </ul>
        </nav>

        <div className="menuhambur">
          <button onClick={() => setOpen(!open)}>
            <i className="bx bx-menu"></i>
          </button>
        </div>
        <nav className={`${open ? "navMobileActive" : "navMobile"}`}>
          <ul>
            <li>
              <a href="#producto" onClick={() => setOpen(!open)}>
                Linea de producto
              </a>
            </li>
            <li>
              <a href="#destacados" onClick={() => setOpen(!open)}>
                {" "}
                Productos
              </a>
            </li>
            <li>
              <a href="#zonas" onClick={() => setOpen(!open)}>
                Zonas
              </a>
            </li>
            <li>
              <a href="#contacto" onClick={() => setOpen(!open)}>
                Contacto
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <style jsx="true">{`
        .contenedor {
          background: var(--primary);
          display: flex;
          color: white;
          padding: 0.7rem 0;
          justify-content: space-around;
          align-items: center;
          width: 100vw;
          z-index: 100;
          position: fixed;
        }
        .navigate {
          display: flex;
          list-style: none;
          gap: 5rem;
          font-size: 20px;
        }
        .navigate a {
          text-decoration: none;
          color: white;
        }
        .menuhambur {
          display: none;
        }
        .navMobileActive {
          display: none;
        }
        .navMobile {
          display: none;
        }
        @media only screen and (max-width: 1024px) {
          .navDesktop {
            display: none;
          }
          .menuhambur {
            display: block;
          }
          .menuhambur button {
            background-color: transparent;
            border: none;
            color: white;
            font-size: 30px;
          }
          .navMobile {
            position: absolute;
            background: var(--primary);
            width: 100%;
            top: 4rem;
            transition: 1s ease-in-out;
            transform: translateY(-140%);
            opacity: 0;
            z-index: -100;
            visibility: hidden;
            display: block;
          }

          .navMobileActive {
            position: absolute;
            background: var(--primary);
            width: 100%;
            top: 4rem;
            transition: 1s ease-in-out;
            transform: translateY(0%);
            opacity: 1;
            display: block;
          }

          .navMobileActive ul,
          a {
            text-align: center;
            list-style: none;
            padding: 0;
            text-decoration: none;
            color: white;
            font-size: 22px;
            margin: 0;
          }

          .navMobileActive li {
            padding: 0.4rem 0;
            border-bottom: 1px white solid;
          }
          .navMobile ul,
          a {
            text-align: center;
            list-style: none;
            padding: 0;
            text-decoration: none;
            color: white;
            font-size: 22px;
            margin: 0;
          }

          .navMobile li {
            padding: 0.4rem 0;
            border-bottom: 1px white solid;
          }
        }
      `}</style>
    </>
  );
};

export default Header;
