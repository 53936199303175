import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TagManager from "react-gtm-module";
const Gracias = () => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        path: "/gracias",
      },
    });
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => navigate("/"), 4500);
  }, [navigate]);
  return (
    <>
      <div className="principal">
        <div className="contenido">
          <div className="contimg">
            <img src="/media/logo.png" alt="" />
          </div>
          <h1>¡ Gracias !</h1>
          <h2>Nos contactaremos pronto para resolver tu consulta</h2>
          <a href="/" className="text-sm">Regresar al inicio</a>
        </div>
      </div>
      <style jsx="true">{`
        h1, h2 {
            margin: 0;
        }
        .principal {
          display: flex;
          height: 100vh;
          width: 100vw;
          place-content: center;
        }
        .contenido {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          justify-content: center;
          align-items: center;
        }
        .contenido img {
          max-width: 200px;
        }
        .contenido h2 {
          text-transform: uppercase;
          font-size: 15px;
          letter-spacing: 1px;
        }
        .contimg {
          background: black;
        }
      `}</style>
    </>
  );
};

export default Gracias;
